.service-main {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.servicetitle {
    font-family: var(--fontfamily);
    font-size: 23px;
    line-height: 33px;
    font-weight: 500;
    background: #622a00;
    padding: 12px 0;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    /* color:var(--primary); */
    text-transform: uppercase;
}

.service-main .fullwidth {
    width: 35%;
}



@media screen and (min-width:1920px) {
    .service-main .fullwidth {
        width: 50%;
    }
}

@media screen and (min-width:2300px) {}

/* 

@media screen and (max-width:1024px) {
} */

@media screen and (max-width:1280px) {
    .servicetitle {
        font-size: 20px;
        line-height: 26px;
    }
}

@media screen and (max-width:1199px) {

    .servicetitle {
        font-size: 16px;
        line-height: 25px;
        padding: 10px 0;
    }
}

@media screen and (max-width:1024px) {
    .service-main .fullwidth {
        width: 45%;
    }
}

@media screen and (max-width:768px) {
    .service-main {        
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width:575px) {
    .service-main .fullwidth {
        width: 75%;
    }

    .servicetitle {
        font-size: 13px;
        line-height: 20px;
        padding: 8px 0;
    }
}