.homeslider {
    /* background: radial-gradient(circle closest-side, #27282D, #202126, #0F1016); */
    background: radial-gradient(circle closest-side, #27282d4f, #20212647, #0f10164a);
}

.homeslider {
    position: relative;
}

.home-bgmain {
    position: absolute;
    top: -30px;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    -webkit-transition: opacity 0.5s ease-in;
    -moz-transition: opacity 0.5s ease-in;
    -o-transition: opacity 0.5s ease-in;
    -ms-transition: opacity 0.5s ease-in;
}

.home-bgmain.visible {
    opacity: 0.70;
}

.home-bgleft {
    width: 35%;
    transform: rotate(-90deg);
}

.home-bgleft svg {
    width: 100%;
    height: 100%;
}

.home-bgright {
    width: 35%;
    transform: rotate(90deg);
}

.home-bgright svg {
    width: 100%;
    height: 100%;
}

.homeslider .slidercontent {
    height: 100vh;
}

.slidercontent .container {
    margin: auto;
}

.imagebox {
    min-height: 240px;
}

.sliderinfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.sliderinfo h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 15px;
}

.sliderinfo h1 {
    text-transform: uppercase;
    color: var(--secondary);
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-family: var(--fontYatra);
    font-weight: 400;
}

.homeslider .p-4 {
    padding: 0px !important;
}

.sliderinfo p {
    color: var(--white);
    width: 70%;
    font-family: var(--fontPoppins);
}

.bghome {
    display: none;
}

@media only screen and (min-width:1920px) {
    .sliderinfo h2 {
        margin-bottom: 30px;
    }

    .sliderinfo p {
        width: 87%;
        font-size: 18px;
        line-height: 27px;
    }

    .sliderinfo h1 {
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:1199px) {
    .sliderinfo p {
        width: 85%;
    }


}

@media only screen and (max-width:1023px) {
    .imagebox {
        min-height: 100%;
    }
}

@media only screen and (max-width:992px) {}

@media only screen and (max-width:768px) {
    .homeslider::before {
        height: 70vh;
    }

    .sliderinfo p {
        line-height: 20px;
        width: auto;
    }

    .sliderinfo h2 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 15px;
    }

    .sliderinfo h1 {
        font-size: 40px;
        line-height: 50px;
    }
}

@media only screen and (max-width:575px) {
    .sliderinfo h1 {
        font-size: 37px;     
    }
    .homeslider .flex.gap-5.pt-12 {
        flex-direction: column;
    }

    .homeslider {
        background: radial-gradient(circle closest-side, #27282D, #202126, #0F1016);
    }

    .home-bgmain.visible {
        display: none;
    }

    .bghome {
        width: 100%;
        height: 800px;
        top: 0;
        display: block;
        position: absolute;
        background: url(../../../public/images/phonefooter.svg) no-repeat center center;
        background-size: cover;
        opacity: 1;
    }

    .homeslider::after,
    .homeslider::before {
        opacity: 0;
    }
}