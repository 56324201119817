.editeandremove {
    position: absolute;
    background: var(--WhiteColor);
    padding:5px 10px;
    top: 10px;
    right: 22px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 #00000052;
    z-index:1;
}
.editeandremove svg{
    width:15px;
    height:15px;
}
.event-detailstable .table{background:#5f5f6a; --bs-table-bg: #5f5f6a;}
.event-detailstable .table th, .table tr td{
    font-size:14px;
    color:#cfcfcf;
}
.event-detailstable thead th:last-child{
    width:30px;
    text-align:center;
}
.event-detailstable tbody tr td:last-child{
    text-align:right;
}
.event-detailstable td{
    position:relative;
}
.tableheader {
    margin-bottom: 20px;
    background: var(--blackgray);
    padding:20px;
    border-radius: 10px;
    flex-direction:column;
    gap:20px;
}
.search-bar{position:relative;}
.search-bar svg {
    fill:var(--WhiteColor);
    position: absolute;
    top:7px;
    right: 10px;
}
.dotsthree svg path{
    fill:#cfcfcf;
}
.pagination{
    gap:5px;
}
.pagination li a {
    width:25px;
    height:25px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--WhiteColor);
    color:var(--BlueColor);
    font-size:12px;
}
.pagination li a.pagination-link.active{
background:var(--RedColor);
color:var(--WhiteColor);
}
.pagination-link.disabled{
pointer-events:none; opacity:0.4;
}
.link-cell {
    max-width:160px; /* Adjust width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title-cell{
    max-width:80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
  }

  .search-bar .form-control {
    height: 41px;
}




/* gallery pictures upload */
.galleryform {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap:10px;
}
.galleryform .form-group {
    width:46%;
}

.galleypictures{display:flex; align-items:end; justify-content:space-between; gap:50px;}
.gallery-item{
    position: relative;
    border: dashed 1px var(--WhiteColor);
    padding:6px 5px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;    
    gap:10px;
}
.gallery-item small{font-size:10px;}
.gallery-item input {
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0;
}
.fileupload {
    background: var(--GrayDarkColor);
    color: var(--WhiteColor);
    padding: 1px 14px;
    border-radius: 20px;
    font-size:12px;
}
.gallery-item label {
    display: flex;
    align-items: center;
    gap:7px;    
    font-size: 12px;
}
.form-group label {
    margin-bottom: 3px;
}
.gallery-item label svg {
    fill: var(--WhiteColor);
    width:20px;
    height:20px;
}
.locationform .btn,
.galleryform .btn {
    padding: 5px 20px;
    font-size: 12px;
}

.pictures-addshow{display:flex; flex-wrap:wrap; gap:15px;}
.gallerypicadd {
    width:23.5%;
    position: relative; 
}

.imagborder{
    border:solid 1px var(--WhiteColor);
    border-radius:10px;
    height:180px;
}
.gallerypicadd img {
    width: 100%;
    object-fit:cover;
    border-radius: 10px;
    height: 100%;
}


.gallerypicadd p {
    background: var(--primary);
    padding:5px;
    border-radius:5px;
    margin-top:10px;
    text-align: center;
    font-size:12px;
}

.tableheader .table>:not(caption)>*>*,
.tableheader tbody tr td,
.tableheader thead th{
padding:5px;
color:var(--WhiteColor);
background:transparent;
font-size: 12px;
}
.tableheader thead th{background:var(--BlueColor) !important;}
.titleedtsave button {
    position: absolute;
    right: 10px;
    top: 10px;    
    cursor: pointer;
}
.titleedtsave.savebtn button{top:19px;}
.titleedtsave button svg{width:15px; height:15px;}
.titleedtsave button svg path{fill:var(--WhiteColor);}
.titleedtsave input {
    width: 100%;
    background:#5c5c5c;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
}


@media only screen and (min-width:1920px) {
    .gallerypicadd p {font-size:14px;}
    .galleryform .form-group{width:47%;}
    .galleryform{flex-wrap:nowrap;justify-content:space-between;}
    .tableheader{gap:30px;}    
    .locationform .btn{
        padding:6px 30px;
        font-size:16px;
    }
    .galleryform .btn {
        padding: 7px 70px;
        font-size:16px;
    }
    .imagborder{
        height:250px;
    }
    .gallerypicadd {
        width:24.2%;
        position: relative; 
    }
    .form-group .gallery-item label {
        font-size: 14px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width:1280px) {
.galleryform .form-group{width:49%}
.galleryform {
    flex-wrap:wrap;    
    justify-content:center;

}
}


@media only screen and (max-width:1199px) {
    .gallerypicadd {
        width: 47.5%;        
    }
    .galleryform .form-group{width:100%}
}