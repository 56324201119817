.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--blackgray);
    padding: 10px 0;
    height: 100vh;
    width:267px;
    transition: transform 0.3s ease-in-out;    
    -webkit-transition:transform 0.3s ease-in-out; 
    -moz-transition:transform 0.3s ease-in-out;    
    -o-transition:transform 0.3s ease-in-out;      
    -ms-transition:transform 0.3s ease-in-out; 
    z-index:9;
}
.sidebar.CloseMenu{
    transform: translateX(-100%);
}
.logosidebar {
    text-align: center;
    position: relative;
    margin-top: -10px;
    padding: 12px 0;
}

.logosidebar img {
    width:100px;
    height:100px;
    margin:auto;
}

.left-sidemneu {
    padding-top:20px;
}
.left-sidemneu ul li a{
color:var(--WhiteColor);
display:flex;
gap:5px;
align-items:center;
font-size:14px;
padding:10px;
border-bottom: solid 1px #4c4b4e;
}
.left-sidemneu ul li a svg{
    fill:var(--WhiteColor);
    height:20px;
    width:20px;
}
.left-sidemneu ul li.active a{
background:var(--orange);
}



@media only screen and (min-width:1920px) {
    .left-sidemneu ul li a svg{
        height:25px;
        width:25px;
    }
}

@media only screen and (max-width:992px) {
    .sidebar{
        left:-100%;
    }
    .sidebar.CloseMenu{left:0;}
    .sidebar.CloseMenu{
        transform: translateX(-0%);
    }
     
    }