.contactpage.preorder::after{
display:none;
}

.contactinfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    gap:10px;
    border-bottom: solid 1px #2e2e2e;
    padding-bottom: 10px;
}
.contactinfo:last-child{margin-bottom:0;padding-bottom:0;border-bottom:none;}
.contactinfo span {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
}
.contactinfo span svg{width:25px; height:25px;}
.contactmain {
    display: flex;
    gap:50px;
}
.information {
    width:40%;
    border: 1px solid #3d3d3d;
    background-color: rgb(41 41 41 / 60%);
    border-radius: 20px;
    padding: 30px 30px 35px;
    position: relative;
    backdrop-filter: blur(8px);
    margin-top: 50px;
}
.title h2 {
    font-size: 30px;
    margin-bottom: 30px;
    font-weight:bold;
    color:var(--orange);
}
.iconsFollow {
    gap:20px;
    margin-top:15px;
}
.instagram.btn svg path {
    fill:none;
}
.instagram.btn{    
    border:2px solid transparent;
    transition: all 0.5s;
    position:relative;
}
.instagram.btn::before{
content:"";
position: absolute;
top:0;
width:100%;
height:100%;
background: linear-gradient( to right, #fcb045,#fd1d1d,#833ab4);
border-radius:30px;
z-index:-1;
}
.instagram.btn:hover::before{
    background:transparent;
}
.emailicon.btn:hover,
.btn.contact:hover,
.whatsapp.btn:hover,
.instagram.btn:hover{
    border:2px solid #fff;
    background:transparent;
}
.whatsapp.btn{
background:#3AC371;
border:2px solid transparent;
transition: all 0.5s;
}

.emailicon.btn{
background:#b5a035;
border:2px solid transparent;
transition: all 0.5s;
}
.emailicon.btn svg path{
    fill:none;
}

.btn.contact{
    background:var(--orange);
    border:2px solid transparent;
    transition: all 0.5s;   
}
.iconsFollow .btn{
    gap:5px;
}
@media only screen and (max-width:1199px) {
    .information {
        padding:20px;
        width: 50%;
    }
}
@media only screen and (max-width:992px) {
    .contactmain{gap:20px;}
    .information{width:70%;}
    .title h2 {
        font-size: 25px;
    }
}
    
@media only screen and (max-width:768px) {
    .contactmain{flex-direction:column-reverse;gap:30px;}
    .information,
    .bookform{width:100%;margin-top:0;}
    .information{padding:20px;}
    .title h2{font-size:20px;margin-bottom:25px;}
}