/* Navigation menu styles */
/* desktop menu show  */
.openmenudesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left:73px;
  gap: 15px;
}

.openmenudesktop ul {
  display: flex;
  gap: 25px;
}

.scrolled .openmenudesktop .logo img {
  width: 100px;
  height: 100px;
}

.openmenudesktop .logo img {
  width: 150px;
  height: 150px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.openmenudesktop li a {
  color: var(--white);
  text-decoration: none;
  padding: 7px 15px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  font-weight: 500;
  border-radius: 30px;
  font-variant: var(--fontPoppins);
}

.openmenudesktop ul li a:hover,
.openmenudesktop ul li a.active {
  background-color: var(--orange);
  color: var(--primary);
}

/* desktop menu show  end*/

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  z-index: 99;
  padding: 20px 0 10px 0;
}

.scrolled header {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  /* border-bottom: solid 1px #1a1a1a; */
}

.scrolled header .desktopmenu ul li.logo img {
  width: 100px;
  height: 100px;
}

.desktopmenu ul li.logo img {
  width: 150px;
  height: 150px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.desktopmenu {
  display: flex;
  justify-content: center;
}

.desktopmenu ul {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.desktopmenu ul li a {
  color: var(--white);
  text-decoration: none;
  padding: 10px 15px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  font-weight: 500;
  border-radius: 30px;
}

.desktopmenu ul li.logo a:hover,
.desktopmenu ul li.logo a,
.desktopmenu ul li.logo a.active {
  background: transparent;
  border-radius: 0;
  display: flex;
  border-radius: 50%;
  padding: 0;
}

.desktopmenu ul li a:hover,
.desktopmenu ul li a.active {
  background-color: var(--orange);
  color: var(--white);
}

.pgonelogo {
  display: none;
}

.hideshowbar {
  display: none;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 70px;
  left: 46%;
  background: var(--orange);
  padding: 10px;
  border-radius: 50%;
}

.hideshowbar span svg {
  fill: var(--white);
  width: 22px;
  height: 22px;
}

.hideshowbar.rotate-top span,
.hideshowbar label {
  display: none;
}

.hideshowbar.rotate-top label {
  display: block;
}

.hideshowbar label svg {
  fill: var(--white);
  width: 22px;
  height: 22px;
}

.headermenu {
  display: none;
}

.headerright {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  right:20px;
  top:50px;
  z-index: 999;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.scrolled .headerright{top:27px;}
.headerright strong {
  background: var(--orange);
  border-radius: 50%;
  width:30px;
  height:30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerright strong svg {
  width:20px;
  height:20px;
}

.headerright strong svg path {
  fill: var(--white);
}

@media only screen and (min-width:1920px) {
  .openmenudesktop {
    padding-left:75px;
  }

  .desktopmenu ul li.logo img {
    width: 200px;
    height: 200px;
  }

  .openmenudesktop ul {
    gap: 10px;
  }

  .openmenudesktop li a {
    padding: 7px 21px 7px 22px;
  }
}

@media only screen and (max-width:1250px) {
  .openmenudesktop ul {
    gap: 20px;
  }
}

@media only screen and (max-width:1199px) {
  .openmenudesktop {
    gap: 10px;
    padding-left: 64px;
  }

  .openmenudesktop li a {
    font-size: 14px;
  }

  .openmenudesktop ul {
    gap: 6px;
  }

  .openmenudesktop .logo img {
    width:140px;
    height:140px;
  }
}

@media only screen and (max-width:1023px) {
  .desktopmenu ul li a {
    font-size: 12px;
  }

  .openmenudesktop .logo img {
    width:110px;
    height:110px;
  }

}

@media only screen and (max-width:992px) {
  .desktopmenu ul li a {
    padding: 8px;
    white-space: nowrap;
  }

  .openmenudesktop {
    padding-left: 56px;
  }

  .openmenudesktop li a {
    font-size: 12px;
    padding: 10px 8px;
  }

  .openmenudesktop ul {
    gap: 2px;
  }

  .openmenudesktop .logo img {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-height:550px) and (max-width:768px) {
  .desktopmenu ul {
    padding-top: 100px;
  }
}


@media only screen and (max-width:768px) {
  header{
    padding-top:10px;
  }
  .openmenudesktop {
    display: none;
  }

  .hideshowbar {
    display: flex;
  }

  .headermenu {
    display: block;
    text-align:center;
    /* align-items: center;
    justify-content: center; */
  }

  .pgonelogo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 999;
    width: 100%;
  }
  .scrolled .pgonelogo img{
    width:70px;
    height:70px;
  }
  .pgonelogo img {
    width:140px;
    height:140px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    margin:auto;
  }

  .desktopmenu ul li.logo a,
  .desktopmenu ul li.logo a.active {
    display: none;
  }

  .desktopmenu ul {
    flex-direction: column;
    gap: 2px;
  }

  .desktopmenu {
    padding: 0;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    height: 100vh;
    z-index: 9;
    top: 0;
    left: 0;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    -ms-transition: transform 0.5s ease-in-out;
    overflow-y: auto;
    align-items: center;
  }

  .desktopmenu.open {
    transform: translateY(0);
    /* Slide down to visible position */
  }

  .desktopmenu ul li {
    width: 100%;
    text-align: center;
  }

  .desktopmenu ul li.logo {
    display: none;
  }

  .desktopmenu ul li a {
    font-size: 14px;
    display: inline-block;
    width: 100%;
    border-radius: 0;
  }

}