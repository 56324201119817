.locationform {
  display: flex;
  width: 100%;
  gap:15px;
  align-items: end;
  flex-direction:column;
}
.locationform .form-group {
  width: 50%;
}
.locationpage select.form-control {
  width: 100%;
  padding-right: 30px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232596be' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position:right 10px center !important;
  background-size: 12px !important;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  filter: grayscale(100%);
}

.form-group label,
.grid-item label,
.locationform .form-group label{
  font-size:12px;
}
.locationform .form-group.locationsearch{width:100%;}
.showLocation {
    position: relative;
    width: 100%;
    /* padding-top:30.25%; */
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .showLocation iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

.loactionmodal .table tr td,
.loactionmodal .modal-body,
.loactionmodal .modal-header{
  color:var(--primary);
}
.loactionmodal .table>:not(caption)>*>*{
  font-size:12px;
}
.loactionmodal .btn{
  padding: 4px 14px;
  border-radius: 5px;
}
.loactionmodal .modal-body{
  max-height:calc(100vh - 200px);
}
.loactionmodal .search-bar {  
  width: 68%;
  margin: auto;
}

.loactionmodal .form-control:focus, .loactionmodal .form-control{
  color:var(--primary) !important;
  background: #ffffff !important;
  border-color: #b7b7b7 !important;
  box-shadow:none;  
}
.loactionmodal .search-bar svg {
  fill: var(--primary);  
}
.locationpage .tableheader tbody tr td.text-red-500{
color:rgb(239 68 68 / var(--tw-text-opacity, 1));
}
.locationpage .tableheader tbody tr td.text-yellow-500{
  color:rgb(234 179 8 / var(--tw-text-opacity, 1));
}
.locationpage .tableheader tbody tr td.text-green-500{
color:rgb(34 197 94 / var(--tw-text-opacity, 1));
}
  
.btn.btn-danger:hover{
  background: var(--RedColor);
}
.btn.primerybutton:hover,
.btn.primerybutton{    
    background: var(--orange);
    border-color: var(--orange);
    color:var(--white);
  }
  .pageLeftspace .selectprev .btn{
    padding: 7px 11px;
    font-size: 14px;
    background:#606060;
  }
  .form-group.selectprev{
    width:20.3%;
  }
  
  @media only screen and (max-width:1199px) {
   .form-group label, .grid-item label, .locationform .form-group label {
      font-size: 10px;
  }
    .pageLeftspace .selectprev .btn{font-size:11px;}
  }
  @media only screen and (max-width:767px) {
    .locationform .form-group,
    .form-group.selectprev{
      width:100%;
    }
    .locationform .btn{margin:auto;}
  }