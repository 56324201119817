.footer-bgmain {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: space-between;
    opacity: 0.6;
}

.footer-bgleft {
    width: 40%;
    transform: rotate(180deg);
}

.footer-bgleft svg {
    width: 100%;
    height: 100%;
}

.footer-bgright {
    width: 40%;
    transform: rotate(180deg);
}

.footer-bgright svg {
    width: 100%;
    height: 100%;
}

.animated-path {
    opacity: 0;
    transition: opacity 0s, stroke-width 0s;
    -webkit-transition: opacity 0s, stroke-width 0s;
    -moz-transition: opacity 0s, stroke-width 0s;
    -o-transition: opacity 0s, stroke-width 0s;
    -ms-transition: opacity 0s, stroke-width 0s;
    stroke-width: 2;
    fill: none;
}

.fillViewport {
    width: 100%;
    height: 100%;
}

footer {
    position: relative;
    width: 100%;
    background: radial-gradient(circle closest-side, #27282D, #202126, #0F1016);
    overflow: hidden;
}

footer::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(../../../public/images/fullbg.svg) no-repeat center top;
    background-attachment: fixed;
    background-size: cover;
    transform: rotate(180deg);
    opacity: 0.5;
}

.footerlocation {
    position: fixed;
    bottom: 20px;
    width: 10%;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: center;
}

.footerlocation span {
    display: none;
}

.locationbtn p {
    position: relative;
    left: 0;
}

.homepage .scrolled .footerlocation {
    width: 10%;
    right: 0;
}

.homepage .footerlocation {
    width: 100%;
    transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out, right 0.5s ease-in-out;
}

.homepage .scrolled .locationbtn span {
    display: none;
}

.homepage .footerlocation span {
    display: inline-block;
    transition: display 0.5s ease-in-oute;
    -webkit-transition: display 0.5s ease-in-out;
    -moz-transition: display 0.5s ease-in-out;
    -o-transition: display 0.5s ease-in-out;
    -ms-transition: display 0.5s ease-in-out;
    /* border: solid 1px #fff; */
    padding: 6px 20px;
    font-size:11px;    
    margin-right: 10px;
    line-height: 20px;
    border-radius:30px;
    background:#ffffff5c;
    color: var(--primary);
}
.homepage .footerlocation span:last-child{
    margin-right: 0;    
}
.homepage .scrolled .locationbtn {
    padding: 20px;
    border-radius: 50%;
}
.homepage .scrolled .locationbtn.twoloactions{gap:0; padding:0;}
.homepage .locationbtn.twoloactions{
    gap:100px;
}
.homepage .locationbtn {
    padding:4px 8px 4px 8px;
    border-radius: 30px;
    transition: padding 0.5s ease-in-out, border-radius 0.5s ease-in-out;
    -webkit-transition: padding 0.5s ease-in-out, border-radius 0.5s ease-in-out;
    -moz-transition: padding 0.5s ease-in-out, border-radius 0.5s ease-in-out;
    -o-transition: padding 0.5s ease-in-out, border-radius 0.5s ease-in-out;
    -ms-transition: padding 0.5s ease-in-out, border-radius 0.5s ease-in-out;
}
@keyframes animate-pulse {
    0% {
       box-shadow: 0 0 0 0 rgba(207, 206, 206, 0.1), 0 0 0 0 rgba(255, 179, 160, 0.1);
    }
    40% {
       box-shadow: 0 0 0 50px rgba(255, 148, 122, 0), 0 0 0 0 rgba(255, 229, 222, 0.1);
    }
    80% {
       box-shadow: 0 0 0 50px rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }
    100% {
       box-shadow: 0 0 0 0 rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }
}

.homepage .scrolled .locationbtn p {
    position: relative;
    left: 0;
    margin:0;
}
.homepage .locationbtn p {
    position: absolute;
    left:auto;
    transition: left 0.5s ease-in-out;
    -webkit-transition: left 0.5s ease-in-out;
    -moz-transition: left 0.5s ease-in-out;
    -o-transition: left 0.5s ease-in-out;
    -ms-transition: left 0.5s ease-in-out;
    border-radius: 50%;
    width:50px;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:linear-gradient(to right, #976F40 0%, #C99B61 50%, #FFCB85 100%);
    animation: animate-pulse 3s linear infinite;
    margin-right: 5px;
}

.locationbtn svg {
    width: 30px;
    height: 30px;
}
.homepage .scrolled .locationbtn svg path{
    fill:var(--white);   
   }
/* .homepage .locationbtn svg path {
    fill: var(--primary);
} */
.locationbtn svg path {
    fill: var(--white);
}

.locationbtn {
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:15px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    box-shadow: 0px 0px 85px -5px var(--primary);    
    background:linear-gradient(to right, #C0935B 0%, #EEBC79 50%, #F0BE7B 100%);
}


.main-bottompart {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 356px;
    z-index: 0;
    opacity: 0.4;
}

.main-bottompart svg {
    width: 100%;
    margin: auto;
}

.footer-main {
    position: relative;
    width: 100%;
    text-align: center;
}

.logofooter img {
    width: 200px;
    height: 200px;
}
.logofooter {
    display: inline-block;
    margin: auto;
}
.socialmedia {
    display: var(--d-flex);
    justify-content: center;
    gap: 15px;
    align-items: center;
    margin-top: 20px;
}
.socialmedia a {
    color: var(--orange);
    background: var(--primary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
}
.socialmedia a:hover {
    color: var(--white);
    background: var(--orange);
}
.socialmedia a svg {
    width: 20px;
    height: 20px;
}
footer.section {
    justify-content: center;
}

.copyrightfirst {
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
}

.copyright {
    margin-top: 10px;
    background: var(--primary);
    padding: 5px 0;
    backdrop-filter: blur(1px);
}
.bottomfooter {
    display: flex;
    font-size: 12px;
    justify-content: center;
    color: var(--secondary);
    flex-direction: column;
}
.footerlbus h5 {
    font-weight: 600;
    margin-top: 15px;
}
.footerlbus {
    text-align: center;
}
.footerlbus p {
    margin-top: 5px;
    display: block;
}

.livelocation,
.todayloaction {
    position: relative;
    width: 50%;
}

.livelocation iframe,
.todayloaction iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: solid 1px var(--white);

}

.week-tabs {
    display: flex;
    justify-content: space-between;
    gap:10px;
}
.week-tab{    
    width: 100%;
    color:#898989;
    padding: 10px;
    border:solid 1px #585858;
    border-radius:5px 5px 0 0;
    transition:all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
}
.week-tab:last-child{ margin-right:0;}
.week-tab:hover,
.week-tab.active{
    border:solid 1px transparent;
    color:var(--white);
    font-weight:500;
    background:linear-gradient(to right, #976F40 0%, #C99B61 50%, #FFCB85 100%);
}

.livelocation-main {
    display: flex;
    gap: 20px;
    background: #fff9f914;
    padding: 40px;
    border-radius: 0 0 10px 10px;
    border: solid 1px #464646bd;
    margin-top: -1px;
    min-height: 545px;
}
.map-container{width:100%; height:100%; min-height:300px; border-radius:10px;}

.boxaddresh {
    background: var(--primary);
    padding:30px;
    border-radius:10px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.boxaddresh h3 {
    font-size: 20px;
    margin-bottom:30px;
    font-weight: 600;
}

.boxaddresh .contactinfo p {
    margin: 0 0 0 38px;
}

.boxaddresh .contactinfo svg path {
    fill: var(--white);
}

.boxaddresh .contactinfo {
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.todayloaction-main .todaylocationsection {
    padding-top: 200px;
}

.not-available {
    width:100%;
    font-size: 18px;
    font-weight: bold;
    color:var(--white);
  }
  
.mobileview{
    display:none;    
}
.mobileview input[type="checkbox"],
.mobileview svg.dropdown-search-clear-icon.gray{
display:none;
}
@media only screen and (min-width:1920px) {
    .homepage .locationbtn {
        padding: 12px 12px 12px 10px;
    }
    .homepage .footerlocation span{font-size:14px; padding:10px 20px;}
    .homepage .locationbtn p{
        width:75px;
        height:75px;
    }
    .boxaddresh h3 {
        font-size: 25px;
        line-height: 35px;
    }

    .livelocation-main {
        gap: 40px;
    }

    .socialmedia a {
        width: 50px;
        height: 50px;
    }

    .socialmedia a svg {
        width: 25px;
        height: 25px;
    }

    .todayloaction-main .todaylocationsection {
        padding-top: 200px;
    }

    .logofooter img {
        width: 350px;
        height: 350px;
    }

    .footerlocation {
        bottom: 60px;
    }

}


/* @media only screen and (min-width:2400px) {
    footer.section {
        padding: 300px 0;
    }
} */

@media only screen and (max-width:1199px) {
    .boxaddresh {
        padding: 20px;
    }

    .locationbtn {
        right:10px;
    }
}

@media only screen and (max-width:992px) {
    .todayloaction-main .todaylocationsection {
        padding-top: 150px;
    }
    .week-tabs{gap:0;}
    .week-tab{width:13%;font-size: 13px;}
    .livelocation-main{padding:20px; min-height:300px; justify-content:center;}
    .boxaddresh h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .boxaddresh .contactinfo {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .boxaddresh .contactinfo span svg {
        width: 20px;
        height: 20px;
    }

    .boxaddresh .contactinfo p {
        margin: 0 0 0 28px;
        font-size: 13px;
    }
    

}

@media only screen and (max-width:768px) {
.inputbox.mobileview{
display:block;
padding:0;
border-radius: 5px 5px 0 0;
/* text-align:center; */
width:100%;
}
.desktopview{
    display:none;
}
    .boxaddresh {
        width: 100%;
    }
    footer::before {
        background: url(../../../public/images/phonefooter.svg) no-repeat center center;
        background-size: cover;
        opacity: 1;
    }
    .bottomfooter {
        flex-direction: column;
        gap: 5px;
    }
    .socialmedia {
        margin-top: 10px;
    }
    .locationbtn span {
        padding-right: 5px;
        margin-right: 5px;
    }

    .locationbtn span::after {
        width: 206px;
    }

    .footerlocation {
        display: none;
    }

    .livelocation-main {
        flex-direction: column;
    }

    .livelocation {
        width: 100%;
    }


    .socialmedia a {
        width: 40px;
        height: 40px;
    }
    .week-tabs {
        justify-content:center;
    }
    .week-tab {
        width: 20%;
        border-radius: 0;
        margin: 0;
    }
}

@media only screen and (max-width:575px) {
    .livelocation-main {
        padding: 10px;
    }
    .week-tab {
        width: 50%;
    }
}