.ourdishes {
  position: relative;
}

.cardpic {
  overflow: hidden;
  height:420px;
  border-radius: 130px;
  border: solid 2px var(--white);
  width: 240px;
}

.cardpic::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgb(0, 0, 0) 20%, rgb(255, 255, 255, 0) 100%);
  opacity: 0.7;
  left: 0;
  border-radius: 130px;
}

.cardpic img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.disc-list {
  counter-reset: step;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px 50px;
  margin-top: 50px;
}

.disc-list li {
  counter-increment: step;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.disc-list li label {
  position: absolute;
  top: -45px;
  border: solid 1px #8d8d8d;
  width: 60px;
  border-radius: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.disc-list li:nth-child(even) label {
  top: auto;
  bottom: -45px;
}

.disc-list li label::before {
  content: counter(step);
  display: block;
  font-size: 14px;
  color: #8d8d8d;
  font-weight: 600;
}

/* The text inside the disc */
.disc-list li span {
  font-size: 16px;
  position: absolute;
  bottom: 50px;
  text-align: center;
  left: 0;
  width: 100%;
  margin: auto;
  padding: 0 15px;
  color: var(--white);
  font-weight: 600;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.disc-list li .cardpic:hover span {
  bottom: 50%;
}

.disc-list li:nth-child(even) {
  margin-top: 25px;
}

.bigscreen {
  width: 100%;
}

/* .OurService .bigscreen,
.heightinscreen .bigscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
} */

@media only screen and (min-width:1920px) {
  .cardpic {
    height: 520px;
    width: 260px;
    border-radius: 170px;
  }

  .cardpic::before {
    border-radius: 170px;
  }

  .disc-list li span {
    font-size: 20px;
    bottom: 80px;
  }

  .disc-list li:nth-child(even) {
    margin-top: 60px;
  }

  .disc-list {
    margin-top: 70px;
  }

  .disc-list li span {
    padding: 0 40px;
  }

  .disc-list li:nth-child(even) {
    margin-top: 50px;
  }
  .todayloaction-main .todaylocationsection .bigscreen {
    height: calc(100vh - 260px);
  }

  .todaylocationsection .bigscreen {
    height: calc(100vh - 180px);
  }

  .bigscreen.pagewidth {
    height: calc(100vh - 363px);
  }



  .bigscreen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 300px);
  }

  /* .OurService .bigscreen,
  .heightinscreen .bigscreen {
    height: calc(100vh - 60px);
  } */
  
}

@media only screen and (min-width:2400px) {
  .cardpic {
    height: 600px;
    width: 300px;
  }

}

@media only screen and (max-width:1280px) {
  .disc-list {
    margin-top: 50px;
  }

  .disc-list li span {
    font-size: 17px;
    bottom: 50px;
  }
  /* .OurService .bigscreen,
  .heightinscreen .bigscreen {
    height:auto;
    margin-top:50px;
  } */
}

@media only screen and (max-width:1199px) {
  /* .cardpic {
    height: 300px;
    width: 200px;
  } */

  .disc-list li span {
    font-size: 14px;
    bottom: 30px;
  }
  .cardpic {
    width: 210px;
  }



}

@media only screen and (max-width:1023px) {
  .disc-list {
    gap: 20px;
  }

}

@media only screen and (max-width:992px) {

  .disc-list {
    flex-wrap: wrap;
    margin-top: 60px;
    gap: 60px 20px;
  }

  .disc-list li span {
    font-size: 16px;
  }

  .disc-list li span {
    bottom: 30px;
    font-size: 14px;
  }

  .cardpic {
    height: 300px;
    width: 155px;
  }
}

@media only screen and (max-width:768px) {

  .disc-list {
    margin-top: 20px;
  }

  .disc-list li span {
    font-size: 12px;
  }

  .disc-list li:nth-child(even) label {
    bottom: -30px;
  }

  .disc-list li label {
    top: auto;
    bottom: -30px;
    width: 40px;
    border-radius: 10px;
    height: 20px;
  }

  .disc-list li:nth-child(even) {
    margin-top: 30px;
  }

  .cardpic {
    height: 250px;
    width: 115px;
  }

  .disc-list li span {
    padding: 0 7px;
  }
}

@media only screen and (max-width:575px) {


  .disc-list li:nth-child(even) {
    margin-top: 0;
  }

  .cardpic {
    height: 270px;
    width: 150px;
  }

  .disc-list {
    gap: 50px 10px;
  }

  .disc-list li span {
    padding: 0 7px;
    font-size: 11px;
  }

}