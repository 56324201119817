@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playball&family=Yatra+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary: #000;
    --secondary: #C2DED1;
    --blackgray: #222;
    --green: #2E770C;
    --greenlight: #CCCC05;
    --orange: #F5812C;
    --reddark: #AA3900;
    --white: #fff;
    --fontfamily: "Montserrat", sans-serif, Arial, Helvetica;
    --fontRaleway: "Raleway", sans-serif;
    --fontYatra: "Yatra One", sans-serif;
    --fontAmita: "Amita", sans-serif;
    --fontPoppins: "Poppins", sans-serif;
    --fontsize: 16;
    --lineheight: 24px;
    --d-flex: flex;
}

html {
    scroll-behavior: smooth;
    color: var(--white);
    background: var(--primary);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* transition: all .3s;
    -webkit-transition: all .3s;
    -moz-transition: all .3s; */
}

body {
    font-family: var(--fontfamily);
    font-weight: 400;
    line-height: var(--lineheight);
    font-size: var(--fontsize);
    color: var(--white);
    /* background: var(--primary); */
    overflow-x: hidden;
    background: url(../../../public/images/fullbg.svg) no-repeat center top;
    background-attachment: fixed;
    background-size: cover;
}

.container {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

:focus-visible,
:focus {
    outline: none;
}

a {
    text-decoration: none;
}

.titleone h2 {
    font-size: 20px;
    color: var(--orange);
    margin-bottom: 10px;
    font-family: var(--fontYatra);
}

.titleone h3 {
    font-size: 40px;
    color: var(--white);
    font-weight: 600;
    line-height: 50px;
    font-family: var(--fontRaleway);
}

.titleone {
    backdrop-filter: blur(5px);
    box-shadow: 0px 25px 35px 0px #3333331a;
    background: linear-gradient(111deg, #ffffff40 -4.87%, #ffffff2b 103.95%);
    width: 100%;
    border-top: solid 1px #646464;
    border-bottom: solid 1px #646464;
    padding: 15px 0;
    font-weight: 500;
    letter-spacing: 1px;
}


.btn {
    border: solid 2px var(--white);
    padding: 10px 30px;
    border-radius: 30px;
    font-size: var(--fontsize);
    line-height: var(--lineheight);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.btn:hover {
    background: var(--orange);
    border-color: var(--orange);
}
.loactionmodalinphone .btn{
    border:solid 1px var(--orange);
    color:var(--blackgray);
    padding: 6px 20px;
  }
  .loactionmodalinphone .btn:hover{    
    color:var(--white);
  }
  .loactionmodalinphone{
    color:var(--primary);
  }
  .loactionmodalinphone .modal-content{margin:auto 20px;}

/* .smooth-scroll {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  } 

   */

.section {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    flex-direction: column;
    gap:60px;
}

.section.free-scroll {
    min-height: auto;
    overflow-y: auto;
    scroll-snap-align: none;
    scroll-snap-stop: unset;
}

/* Optional: Style the scrollbar */
::-webkit-scrollbar {
    width: 8px;    
}

::-webkit-scrollbar-track {
    background: #f1f1f1;    
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Responsive map container */
.food-menu-main,
.gallerypagemain {
    position: relative;
    z-index: 1;
}

.gallerypic {
    min-height: 300px;
}

.blurcolor {
    background: rgb(86 86 86 / 38%);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #3d3d3d;
}

.p-4 {
    padding: 1rem !important;
}

.border-white\/\[0\.2\] {
    border-color: rgb(255 255 255 / 0.2) !important;
}

.gap-5 {
    gap: 1.25rem !important;
}

.ptinner {
    padding-top: 200px;
    padding-bottom: 80px;
}


.websiteloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    visibility: visible;
    backdrop-filter: blur(30px);
}

.websiteloader div {
    width: 200px;
    height: 200px;
}

.modalarrow {
    position: absolute;
    background: var(--white);
    border-radius: 50%;
}
.modalarrow:disabled{ 
    cursor:not-allowed;
    background: #c9c9c9;
} 

.modalleft {
    left: 20px;
}

.modalright {
    right: 20px;
}

@media only screen and (min-width:1920px) {
    .titleone {
        padding: 20px 0;
    }
    .section{gap:0;}
    body {
        background: url(../../../public/images/fullbg.svg) no-repeat -10px -12px;
        background-attachment: fixed;
        background-size: cover;
    }

    .websiteloader div {
        width: 300px;
        height: 300px;
    }

    /* .titleone h4{margin-bottom:40px;} */
    .titleone h3 {
        font-size: 60px;
        line-height: 70px;
    }

    .ptinner {
        padding-bottom: 100px;
        padding-top: 200px;
    }

}

@media only screen and (min-width:2300px) {
    body {
        background: url(../../../public/images/fullbg.svg) no-repeat  3px -24px;
        background-attachment: fixed;
        background-size: cover;
    }

    /* .ptinner {
        padding-bottom: 200px;
    } */

}

@media only screen and (max-width:1280px) {
    .gallerypic {
        min-height: 200px;
    }

    .titleone h3 {
        font-size: 40px;
        line-height: 50px;
    }

}

@media only screen and (max-width:1199px) {
    .titleone h3 {
        font-size: 30px;
        line-height: 40px;
    }

    .titleone {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .title h2 {
        font-size: 25px;
    }
}

@media only screen and (max-width:992px) {
    .ptinner {
        padding-top: 180px;
    }

    .titleone h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .titleone h3 {
        font-size: 40px;
        color: var(--white);
        line-height: 50px;
    }

}

@media only screen and (max-width:768px) {

    body {
        font-size: 14px;
        line-height: 18px;
    }

    .titleone h3 {
        font-size: 27px;
        line-height: 35px;
    }
}

@media only screen and (max-width:575px) {}