.OurSpecialDish {
  /* margin-top: 100px; */
  position: relative;
  overflow: hidden;
  height: 50vh;
  background: url(../../../public/images/slider2.webp) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
}

.homedownbg {
  position: relative;
  overflow: hidden;
  height: 50vh;
  background: url(../../../public/images/catering.webp) no-repeat center;  
  background-attachment: fixed;
  background-size: cover;    
}

@media only screen and (min-width:1920px) {
  .OurSpecialDish {
    height: 40vh;
  }
}

@media only screen and (max-width:1024px) {}

@media only screen and (max-width:768px) {
  .homedownbg,
  .OurSpecialDish {
    height: 30vh;
    margin-top:50px;
    /* background-attachment: scroll; */
    -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);  
  }
}