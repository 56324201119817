.loginpage{
    background-color: #080710;
    height:100vh;
}
.loginform{
    width:450px;
    background-color: rgba(255,255,255,0.13);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    padding:40px;
}
form.loginform *{    
    color:var(--WhiteColor);
    letter-spacing: 0.5px;
    outline: none;
    border: none;    
}
.forgatepassword {
    font-size: 13px;
    display: block;
    text-align: center;
    margin-top:20px;
}
.loginlogo{text-align:center; margin-bottom:40px;}
.loginlogo img{
    width: 140px;
    height: 140px;
    margin:auto;
}
.loginform h5{
    font-size:16px;
    font-weight: 500;
    line-height:24px;
    text-align: center;
    color:var(--WhiteColor);
    margin-top:10px;
}
.loginform input{
    display: block;
    height: 50px;
    width: 100%;
    background: rgba(255,255,255,0.07) !important;
    border-radius: 3px;
    padding:10px;    
    font-weight:600;
    font-size:14px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
.loginform::placeholder{
    color: #e5e5e5;  
    background: rgba(255,255,255,0.07) !important;  
}

.loginform button {
    width: 100%;
    background: var(--YellowColor);
    color: var(--WhiteColor);
    padding: 12px 0;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
form.loginform button svg path{
    fill:var(--WhiteColor);
}
.formfield{
    position:relative;
    margin-bottom:30px;
}
.formfield span {
    position: absolute;
    right: 0;
    top: 0px;
    background:var(--primary);
    padding: 14px 10px;
}
.formfield span svg{
    width:22px;
    height:22px;        
}
.formfield span svg path{
    fill:var(--WhiteColor);
}
.emailform .formfield span svg path{
    fill:none;
}

