:root {
    --BoxBg: linear-gradient(111deg, rgba(255, 255, 255, 0.25) -4.87%, rgba(255, 255, 255, 0.17) 103.95%);
    --BackdropFilter: blur(25px);
    --BorderRadius: 20px;
    --BorderRadius25: 25px;
    --BorderRadius50: 50px;
    --BorderRadius100: 100px;
    --border: solid 1px #fff;
    --boxShadow: 0px 25px 35px 0px rgba(51, 51, 51, 0.10);
    --GoldColor: #FFC032;
    --RedColor: #FF0000;
    --PurpleColor: #513F95;
    --YellowColor: #ff8225;
    --OrangeDarkColor: #FF9900;
    --GrayDarkColor: #9C9C9C;
    --BlueColor: #080710;
    --GrayColor: #B1B1B1;
    --BlueDarkColor: #3069B1;
    --bluelight: #2365AD;
    --BlackColor: #000;
    --WhiteColor: #fff;
}

.box-default {
    background: var(--BoxBg);
    border: var(--border);
    box-shadow: var(--boxShadow);
    backdrop-filter: var(--BackdropFilter);
}

.pt0 {
    padding-top: 0;
}

.pb {
    padding-bottom: 0;
}

.pb200 {
    padding-bottom: 200px;
}

.pt200 {
    padding-top: 200px;
}

.pt100 {
    padding-top: 100px;
}

.mt100 {
    margin-top: 100px;
}

.mb100 {
    margin-bottom: 100px;
}

.mt,
.mb {
    margin: 0;
}

.pb100 {
    padding-bottom: 100px;
}

.pt50 {
    padding-top: 50px;
}

.pb50 {
    padding-bottom: 50px;
}

.pt115 {
    padding-top: 115px;
}

.container-fluid {
    max-width: 1920px;
}

.container {
    max-width: 1240px;
}

.titletwo {
    margin-bottom: 50px;
}

.title1 {
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    color: var(--RedColor);
}

.titletwo h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: normal;
}

.titletwo h2 span {
    color: var(--RedColor);
}

.title3 {
    color: var(--YellowColor);
    font-size: 64px;
}

/* .btn:hover,
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    border:solid 2px transparent
} */
.pageLeftspace .tablescroll .btn{
padding:0 5px;
}
.pageLeftspace .btn {
    white-space: nowrap;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    border: solid 2px transparent;
    cursor: pointer;
    background: var(--YellowColor);
    border-radius: 5px;
    padding: 7px 30px;
}

.btn label {
    position: relative;
    z-index: 1;
    color: var(--WhiteColor) !important;
    cursor: pointer;
}

.btn svg {
    margin-right: 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    ;
    -moz-transition: all 0.5s;
    ;
    -o-transition: all 0.5s;
    ;
    -ms-transition: all 0.5s;
    ;
}

.btn svg path {
    fill: var(--WhiteColor);
}

.btn-danger {
    background: var(--RedColor);
}

.btn-danger span {
    background: var(--BlackColor);
}

.btn-dark {
    background: var(--BlackColor);
}

.btn-purpal {
    background: var(--PurpleColor);
}

.btn-purpal span {
    background: var(--BlueDarkColor);
}

.btn-purpal:focus-visible,
.btn-purpal:active,
.btn-purpal:hover {
    color: var(--WhiteColor);
    background-color: #331d89;
    border: none;
}

.tablescroll{
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}
.pageLeftspace {
    margin-left:288px;
    padding-top:85px;
    margin-right:20px;
    padding-bottom: 10px;
    transition: all 0.5s;
    -webkit-transition:all 0.5s ease; 
    -moz-transition:all 0.5s ease;    
    -o-transition:all 0.5s ease;      
    -ms-transition:all 0.5s ease; 
}
.sidebar-open .pageLeftspace{
    margin-left:20px;
}
@media only screen and (min-width:1920px) {
    .pageLeftspace {
        margin-left: 307px;     
        margin-right: 30px;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width:992px) {
   .pageLeftspace{margin-left:20px;}
   .form-group label, .grid-item label, .locationform .form-group label{
    font-size:11px;
   }
 }
 
@media screen and (max-width:1199px) {
    .container {
        padding: 0 20px;
    }

    .row {
        margin: 0 -20px;
    }

}

@media screen and (max-width:992px) {
    body {
        font-size: 14px;
        line-height: 20px;
    }

    .btn {
        padding: 10px 20px;
        font-size: 14px;
    }

    :root {
        --BorderRadius50: 20px;
        --BorderRadius100: 50px;
    }

    .titletwo {
        margin-bottom: 30px;
    }

    .titletwo h2 {
        font-size: 35px;
    }

    .pt115,
    .pt100 {
        padding-top: 50px;
    }

    .pb100 {
        padding-bottom: 50px;
    }

    .pt50 {
        padding-top: 25px;
    }

    .pb50 {
        padding-bottom: 25px;
    }

    .mt100 {
        margin-top: 50px;
    }

    .mb100 {
        margin-bottom: 50px;
    }

}

@media screen and (max-width:767px) {
    .btn {
        padding: 8px 10px;
        font-size: 12px;
    }

    .btn svg {
        width: 20px;
        margin-left: 2px;
    }

    .titletwo h2 {
        font-size: 25px;
    }

    .titletwo {
        margin-bottom: 20px;
    }

    .title1 {
        font-size: 32px;
        line-height: 35px;
    }
}