.header-top{
    background: var(--blackgray);
    padding:15px 20px;
    margin-left:267px;
    position:fixed;
    top:0;
    right:0;
    width:calc(100% - 267px);
    z-index: 999;
}
.sidebar-open .header-top{
    margin-left:0;
}
.page-title{
    color:var(--WhiteColor);
    gap:20px;
}
.page-title svg path{
fill:var(--WhiteColor);
}
.user{
    display:flex;
    gap:20px;
}
.user svg{
    width:35px;
    height:35px;
}
.logout{
    background:var(--WhiteColor);
    border: none;
    padding:0 10px;
    border-radius: 5px;
    display: flex;
    align-items:center;
    color:var(--primary);
    font-size:14px;
    gap:5px;
}
.logout svg {
    width:20px;
    height:20px;
}


@media only screen and (max-width:992px) {
    .header-top{
    margin-left:0;
    width:100%;
    }    
    }